.bonus-mobile {
    width: 320px;
    max-width: 320px;
    margin: 0 auto;
    height: 350px;
    background: rgba(0, 32, 98, 0.3);
    position: absolute;
    overflow: hidden;
    box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.40);
 
    padding: 20px;
    border-radius: 5px;
}

.bonus__wrap-mobile {
    display: flex;
    flex-direction: column;
    row-gap: 20px;
}

.bonus__wrap__title-mobile {
    margin: 0px;
    padding: 0px;
    color: #FFFFFF;
    font-family: 'Montserrat', sans-serif;
    font-size: 22px;
    font-weight: 600;
    text-transform: uppercase;
}

.bonus__wrap__text-mobile {
    color: #FFFFFF;
    font-family: 'Montserrat', sans-serif;
    font-size: 16px;
    font-weight: 400;
}

.bonus__icon-mobile {
    width: 130px;
    height: 130px;
    text-align: center;
}

.bonus__circle__background-mobile {
    position: absolute;
    bottom: -170px;
    right: -340px;
    width: 500px;
    height: 500px;
    opacity: 0.6;
}

.bonus2-mobile {
    width: 320px;
    max-width: 320px;
    height: 350px;
    background: rgba(0, 32, 98, 0.3);
    position: relative;
    overflow: hidden;
    box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.40);
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px;
    border-radius: 5px;
}

.bonus__wrap2-mobile {
    display: flex;
    flex-direction: column;
    row-gap: 20px;
}
